.p-archive-4{
  padding-bottom: 45px;
  @include pc{
    padding-bottom: 100px;  //デザイン上の余白 - .page-numbers のmargin-bottom
  }
  &--title{
    margin-top: -120px;
    font-size: 3.2rem;
    text-align: center;
    color: #231815;
    letter-spacing: 0.05em;
    &::before{
      content:"NEWS";
      font-size: 80px;
      text-transform: uppercase;
      display: block;
      color: #FFF;
      margin-top: 5px;
      position: relative;
      z-index: -1;
      top: 72px;
      -webkit-text-stroke: 1px #e0e0e0;
    }
      &--wrap{
        padding:0 0 60px 0;
        background: none;
      }
    }

    &--list,
    &--pager .page-numbers {
      margin-bottom: 10px;
    }

    &--item{
      border-bottom: 1px solid #C4C4C4;

      &:first-child {
        border-top: 1px solid #C4C4C4;
      }

      a{
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        padding:17px 0;
        color: #000000;
        text-decoration: none;
        @include pc{
          padding:35px 0;
          align-items: center;
          flex-wrap: nowrap;
        }
      }
      &-date{
        margin-right: 8px;
        font-size: 12px;
        letter-spacing: 0.05em;
        @include pc{
          margin-right: 20px;
        }
      }
      &--category-list{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style: none;
        @include pc {
          width: 12%;
          max-width: 100px;
          margin-right: 23px;
        }
      }
      &--category-item{
        padding: 4px 8px;
        font-size: 10px;
        color: #fff;
        background: #000;
        border-radius: 1px;
        text-align: center;
        @include tab {
          margin-right: 8px;
          margin-bottom: 8px;
        }
        @include pc{
          &:not(:last-of-type){
            margin-bottom: 8px;
          }
        }
      }
      &--label{
        margin-bottom: 8px;
        padding: 4px 0;
        width: 40px;
        border-radius: 1px;
        font-size: 10px;
        letter-spacing: 0.05em;
        color: #fff;
        background: #2491FF;
        text-align: center;
        text-transform: uppercase;
        @include pc{
          margin-bottom: 0;
          margin-right: 23px;
        }
      }
      &--heading{
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.05em;
        line-height: 1.6;
        @include pc{
          flex: 1;
        }
      }
      &:hover{
        background-color:#e6e6e6;
        transition: 0.5s;
      }
    }
    &--pager{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 26px;

      @include pc {
        margin-top: 51px;
      }

      .page-numbers{
        display: block;
        margin-right: 5px;
        margin-left: 5px;
        width: 37px;
        min-height: 37px;
        padding: 3px 1px;
        border: 1px solid #CECECE;
        border-radius: 3px;
        color: #000;
        font-size: 16px;
        letter-spacing: 0.05em;
        text-align: center;
        text-decoration: none;

        &.current{
          border: 1px solid #231815;
          background: #231815;
          color: #fff;
        }
        &.next,&.prev{
          font-weight: 700;
          position: relative;
          border:none;
          &::after{
            transform: rotate(45deg) translate(-50%);
            position: absolute;
            content: '';
            width: 6px;
            height: 6px;
            top: 50%;
            left: 50%;
          }
        }

        &.next{
          &::after{
            left: 50px;
            border-top: solid 2px #000;
            border-right: solid 2px #000;
          }
        }
        &.prev{
          &::after{
            left: -20px;
            border-bottom: solid 2px #000;
            border-left: solid 2px #000;
          }
        }
      }
    }


    &--content{
      .l-container{
        @include pc{
          max-width: calc( 900px + 5% * 2);
        }
      }
    }
    
}
