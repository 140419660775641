.p-company-1{
  padding-top: 0px;
  padding-bottom: 100px;
  overflow: hidden;

  p{
    letter-spacing: .05em;
    text-align: center;
  }

  &--title{
    font-size: 3.2rem;
    text-align: center;
    height: 30px;
      &-en{
        font-size: 70px;
        text-transform: uppercase;
        display: block;
        color: #FFF;
        margin-top: 5px;
        position: relative;
        z-index: -1;
        top: -120px;
        -webkit-text-stroke: 1px #e0e0e0;
      }
  }

  &--catch{
    margin:60px;
    p{
      @include pc{
        width: 900px;
        margin: 0 auto;
      }
    }
  }
  //company-1新規追加
  &--content{
    .l-company--table{
      vertical-align: bottom;
      font-size: 15px;
      padding-bottom: 90px;
      border-bottom: 4px dashed #E6E6E6;
      @include pc{
        width: 900px;
        margin: 0 auto;
      }
    }
    tr{
      border-top: 1px solid #E6E6E6;
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      @include pc{
        flex-direction: unset;
      }
      &:last-of-type{
        border-bottom: 1px solid #E6E6E6;
        th{
          @include pc{
            padding-top:40px;
          } 
        }
      }
      th{
        background-color: #AAAAAA;
        color: #FFF;
        width: 100%;
        padding: 10px;
        @include pc{
          padding-top: 20px;
          width: 150px;
        }
      }
      td{
        width: 100%;
        padding: 20px;
        @include pc{
          width: 750px;
        }
        ul{
          padding-left: 20px;
        }
      }
    }
  }
  &--greeting{
    @include pc{
      margin-top: 90px;
    }
    &--item{
      width: 100%;
      @include pc{
        width: 900px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
      }
      &--content{
        padding-bottom: 30px;
        @include pc{
          width: 390px;
        }
        &--title{
          font-size: 32px;
          padding:70px 0 40px 0;
        }
        &--text{
          font-size: 15px;
          line-height: 2;
          color: #505050;
          text-align: left !important;
        }
      }
      &--image{
        width: 100%;
        @include pc{
          width: 450px;
          height: 500px;
        }
        img{
          width: 100%;
          border-radius: 20px;
        }
      }
    }
  }


}





