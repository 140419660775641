$pc: 768px; // PC
$tab: 767px; // タブレット
$pc-width: 1280px;
$font-color:#333;
$main-font: 
  "Helvetica Neue",
  "Segoe UI",
  "Hiragino Kaku Gothic ProN",
  "Hiragino Sans",
  Meiryo,
  sans-serif;
  //明朝体の場合
  //"Noto Serif JP", serif;
$sub-font: 'Open Sans', sans-serif;
$container-padding-hori: 5%; //.l-containerの水平方向のpadding
$container-max-width: 1400px; //デフォルトのインナー幅の最大幅
$main-color: #1EA2E4;
$sub-color1: #e6e7f0;
$sub-color2: #6d6d6d;
$z-index-max: 9999; //z-indexの最大値を設定

@mixin pc {
  @media (min-width: ($pc)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

@mixin clear{
  display: block;
  clear: both;
  content: '';
}

@mixin container-max-width($max-width) { //インナー幅とコンテンツ幅の余白を元に、コンテンツ幅のmax-widthを返す
  max-width: calc( #{$max-width} + #{$container-padding-hori} * 2 );
}

@mixin contents{
  width: $pc-width;
  margin-left: auto;
  margin-right: auto;
}

@mixin sp-contents{
  box-sizing: border-box;
  width: 100%;
  padding:0 20px;
}

@mixin list-none{
  list-style: none;
  padding-left: 0;
}

@mixin objectfit {
  object-fit: cover;
  font-family:"object-fit:cover;";
}

@mixin center-center{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@mixin center-top{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin center-bottom{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin center-right{
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
}

@mixin center-left{
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
}

@mixin arrow-right{
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  content: '';
}

@mixin arrow-bottom{
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  position: absolute;
  content: '';
}

@mixin arrow-left{
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  content: '';
}