.c-button-3{
  a{
    display: block;
    width: 100%;
    padding: 14px 5px;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 0;
    color: #FFF;
    text-decoration: none;
    text-align: center;
    background: #154A92;
    @include pc{
      max-width: 203px;
    }
  }
}
