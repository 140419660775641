.p-feature-8{
  overflow: hidden;
  padding-bottom: 80px;
  p{
    letter-spacing: .05em;
    text-align: center;
  }

  &--title{
    font-size: 3.2rem;
    text-align: center;
    height: 30px;
      &-en{
        font-size: 70px;
        text-transform: uppercase;
        display: block;
        color: #FFF;
        margin-top: 5px;
        position: relative;
        z-index: -1;
        top: -120px;
        -webkit-text-stroke: 1px #e0e0e0;
      }
  }

  &--catch{
    padding-top: 66px;
    margin-bottom: 120px;
    p{
      @include pc{
        width: 900px;
        margin: 0 auto;
      }
    }
  }

  &--headline{
    font-weight: bold;
    font-size: 2.4rem;
    margin-bottom: 22px;
    letter-spacing: .1em;
    line-height: 1.8;
  }

  &--inner{
    @include pc{
      padding-top: 40px;
      padding-bottom: 40px;
    }
    position: relative;
  }

  &--count{
    position: absolute;
    font-size: 32.6rem;
    color: #F7F7F7;
    font-weight: 600;
    z-index: 3;
    @include pc{
      top: -200px;
      right: 0;
      z-index: 0;
    }
    @include tab{
      top: -60px;
      right: 20px;
      font-size: 10rem;
    }
  }

  &--block--number{
    color: #A0A0A0;
    font-size: 15px;
    position: relative;
    padding-left: 20px;
    margin-bottom: 25px;
      &::before{
        content: '';
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 100%;
        background: #FFD72B;
        position: absolute;
        left: 0px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
  }

  &--block{
    &:not(:last-of-type){
      margin-bottom: 80px;
    }
  }

  &--block:nth-of-type(even){
    @include pc{
      .p-feature-8--block--figure{
        left: auto ;
        right: $container-padding-hori;
      }
      .p-feature-8--block--text{
        margin-left: $container-padding-hori;
        margin-right: auto;
      }
      .p-feature-8--block--number::before{
        background: #E05595;
      }
      .p-feature-8--count{
        left: 0;
        right: auto;
      }
    }
    @include tab{
      .p-feature-8--block--figure{
        margin-left: calc(50% - 50vw);
        margin-right: auto;
      }
    }
  }
  &--block:nth-of-type(3){
    .p-feature-8--block--number::before{
      background: #2B9DFF;
    }
  }

  &--block--text{
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 3px 10px #e0e0e0;
    @include pc{
      width: calc((100% + #{$container-padding-hori}) - 745px);
      padding: 122px 85px;
      margin-top: 130px;
      margin-left: auto;
      margin-right: $container-padding-hori;
    }
    @include tab{
      padding: 45px 15px;
    }
      p{
        line-height: 2;
        margin-bottom: 20px;
        text-align: left;
      }
  }

  &--block--figure{
    @include pc{
      width: 745px;
      height: 436px;
      position: absolute;
      top: 0;
      left: $container-padding-hori;
      z-index: 1;
    }
    @include tab{
      margin-left: auto;
      margin-right: calc(50% - 50vw);
      margin-top: -25px;
      width: 80%;
      z-index: 2;
      position: relative;
      height: 250px;
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }



}


