.p-service-11{
  padding-top: 0px;
  padding-bottom: 100px;
  overflow: hidden;

  p{
    letter-spacing: .05em;
    text-align: center;
  }

  &--title{
    font-size: 3.2rem;
    text-align: center;
    height: 100px;
      &-en{
        font-size: 70px;
        text-transform: uppercase;
        display: block;
        color: #FFF;
        margin-top: 5px;
        position: relative;
        z-index: -1;
        top: -120px;
        -webkit-text-stroke: 1px #e0e0e0;
      }
  }
  &--title--wrap{
    padding: 70px 0;
    background-image: url(../img/service/print-title.png);
    background-size: cover;
    @include pc{
      padding: 135px 0;
    }
    .l-container{
      @include pc{
        @include container-max-width(1280px);
      }
    }
    .p-service-11--title{
      font-family: "ヒラギノ角ゴシック";
      font-weight: 600;
      // width: 82px;
      height: 176px;
      margin: 0 auto;
      padding: 17px;
      line-height: 1.5;
      background: #FFF;
      border-radius: 10px;
      writing-mode: vertical-lr;
    }
  }
  .p-service-11--drone{
    background-image: url(../img/service/drone-title.png);
    background-position: center;
  }
  .p-service-11--photo{
    background-image: url(../img/service/photo-title.png);
  }
  &--catch{
    margin-top:60px;
    margin-bottom: 120px;
    p{
      @include pc{
        width: 900px;
        margin: 0 auto;
      }
    }
  }

  &--details{
    &--title{
      padding: 90px 0 60px 0;
      text-align: center;
      font-size: 28px;
    }
    &--item{
      margin-bottom: 60px;
      @include pc{
        display: flex;
      justify-content: space-between;
      }
      &--figure{
        width: 100%;
        @include pc{
          width: 48%;
        }
        img{
          width: 100%;
          height: 470px;
          object-fit: cover;
          border-radius: 20px;
        }
      }
      .sp-block{
        @include pc{
          display: none;
        }
      }
      .pc-block{
        display: none;
        @include pc{
          display: block;
        }
      }
      &--contents{
        width: 100%;
        padding-top: 10px;
        @include pc{
          width: 48%;
          padding-top: 70px;
        }
        &--title{
          font-size: 24px;
          position: relative;
          padding: 10px 0 20px 85px;
          @include pc{
            font-size: 28px;
            padding:20px 0 30px 134px;
          }
          span{
            font-family: "Roboto";
            font-size: 70px;
            -webkit-text-stroke: 1px #6e6e6e;
            color: #FFF;
            position: absolute;
            left: 0;
            top: -5px;
            line-height: 1;
            @include pc{
              font-size: 100px;
              top: -10px;
            }
          }
        }
        &--text{
          text-align: left !important;
          
        }
      }
    }
  }








  
  &--content--list{
    padding-bottom: 60px;
    .p-service-11--list--title{
      font-size: 32px;
      text-align: center;
      padding: 40px 0;
    }
    .p-service-11--list{
      display: flex;
      flex-wrap: wrap;
      font-family: "ヒラギノ角ゴシック";
    }
    .p-service-11--item{
      position: relative;
      text-align: left;
      margin-bottom: 50px;
      @include pc{
        padding-bottom: 40px;
        margin-bottom: 0px;

      }
      a{
        text-decoration: none;
        color: inherit;
      }
      &--figure{
        @include pc{
          width: 100%;
          height: 218px;
        }
        img{
          height: 100%;
          border-radius: 10px;
        }
      }
      &:not(:nth-of-type(3n)) {
        // margin-bottom: 50px;
        @include pc{
          margin-right: 45px;
          margin-bottom: 0;
        }
      }
      @include pc{
        width: calc((100% - 45px * 2) / 3);
        padding-bottom: 80px;
      }
      
      &--title{
        font-size: 15px;
        padding: 20px 15px;
        line-height: 1.53;
      }
      &--tag{
        font-size: 14px;
        width: 28px;
        height: auto;
        background-color: #FFF;
        padding: 8px 5px;
        border-radius: 0 0 5px 5px;
        position: absolute;
        top: 0px;
        left: 15px;
        line-height: 1.42;
        writing-mode: vertical-lr;
        ul{
          padding-top: 0;
          margin: 0;
          list-style: none;
        }
      }
      &--text{
        font-size: 12px;
        color: #AAAAAA;
        line-height: 1.75;
        padding:0 15px;
        margin-bottom: 20px;
        
      }
      .p-service-11--item--text--tag{
        padding: 0 10px;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li{
          font-size: 12px;
          color: #AAAAAA;
          line-height: 1.75;
          padding: 0 5px;
          margin-bottom: 8px;
        }
      }
      .c-btn{
        margin-left: 15px;
        margin-top: 0;
        @include pc{
          position: absolute;
          bottom: 30px;
          margin-top: 50px;
        }
      }
      &:hover{
        box-shadow: 3px 6px 10px gray;
        border-radius: 20px;
        transition: 0.5s;
      }
    }
  }

  &--flow{
    .l-container{
      background-color: #F5F5F5;
      padding: 0 0 0 5%;
      @include pc{
        padding: 0 5%;
      }
    }
    &--title{
      font-size: 32px;
      text-align: center;
      padding: 40px 0;
    }
    &--list{
      display: -webkit-box;
      padding-bottom: 40px;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      @include pc{
        display: flex;
        margin: 0 auto;
        overflow-x: unset;
        white-space: unset;
        -webkit-overflow-scrolling: unset;
        justify-content: space-between;
      }
      .p-service-11--flow--item{
        height: auto;
        width: 80%;
        text-align: center;
        background-color: #FFF;
        border-radius: 10px;
        position: relative;
        margin-right: 10%;
        &:not(:nth-of-type(4n)) {
          // margin-right: 10%;
          @include pc{
            margin-right: 50px;
            margin-bottom: 0;
          }
        }
        @include pc{
          width: calc((100% - 0px * 4) / 4);
          margin-right: unset;
        }
        &--figure{
          img{
            width: 100%;
          }
        }
        &--title{
          font-size: 18px;
          padding-top: 15px;
        }
        &--text{
          line-height: 1.75;
          font-size: 15px;
          padding: 20px 5px;
        }
        &:after{
          content: "";
            background-image: url(../img/other/flow-arrow.png);
            background-size: cover;
            width:11px;
            height: 20px;
            display: inline-block;
            position: relative;
            top: -300px;
            right: -56%;
        
          @include pc{
            content: "";
            background-image: url(../img/other/flow-arrow.png);
            background-size: cover;
            width:11px;
            height: 20px;
            display: inline-block;
            position: absolute;
            top: 250px;
            right: -30px;
          }
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
      }
    }
  }
  .c-btn{
    margin-left: 15px;
  }
}






  

  



