.c-cta-5{
  text-align: center;
  margin-bottom: 60px;
  .l-container{
    color: #fff;
    background: linear-gradient( 135deg, rgba(34,34,34,0.7), rgba(64,64,64,0.7)),
    url(../img/cta/cta5-bg.png);
    background-size: cover;
    border-radius: 30px;
    width: 90%;
    background-position: center;
    @include pc{  
      height: 338px;
    }
  }
  

  &--title{
    font-size: 2.4rem;
    font-family: "ヒラギノ角ゴシック";
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
    line-height: 1.5;
    padding-top: 80px;
  }
  &--title--en{
    position: relative;
    font-size: 30px;
    text-transform: uppercase;
    display: block;
    color: transparent;
    margin-top: 5px;
    top: -200px;
    -webkit-text-stroke: 1px #e0e0e0;
    font-weight: bold;
    @include pc{  
      font-size: 80px;
      top: -228px;
    }
  }


  &--row{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 35px;
    position: relative;
    top: -30px;
    @include pc{  
      top: -130px;
    }
  }

  &--item{
    text-align: center;
    display: flex;
    flex-direction: column;
      @include pc{
        width: calc(50% - 20px);
        &:first-of-type{
          margin-right: 40px;
        }
      }
      @include tab{
        width: 100%;
          &:first-of-type{
            margin-bottom: 25px;
          }
      }
  }

  &--item--text{
    font-size: 15px;
    font-weight: bold;
    line-height: 1.5;
  }
  .c-cta-5--tel{
    font-size: 26px;
    @include pc{
      font-size: 46px;
    }
    b{
      font-size: 22px;
    }
  }
  .c-cta-5--time{
    font-size: 12px;
    font-family: "ヒラギノ角ゴシック";
    font-weight: 600;
  }

  .c-button-3{
    margin-top: 20px;
    a{
      color: #231815;
      background-color: #FFF;
      border: none;
      @include pc{  
        max-width: 430px;
        height: 80px;
        font-size: 18px;
        line-height: 50px;
      }
      &:after{
        @include pc{
          width: 20px;
          height: 6px;
          top: 36px;
        }
      }
      &:hover {
        color: #FFF;
        background-color: #CE3E3E;
        position: relative;
        z-index: 1;
        border: none;
      }
      &:before{
        background-color: #CE3E3E;
      }
      &:after{
        background-image: url(../img/other/btn-arrow-bl.png);
      }
      &:hover::after{
        background-image: url(../img/other/btn-arrow.png);
      }
    }
  }


  &--tel{
    text-decoration: none;
    font-size: 4rem;;
    color: #fff;
    vertical-align: middle;
    position: relative;
    display: inline-block;
    font-weight: 600;
      @include pc{
        margin-right: 3px;
      }
      svg{
        @include tab{
          width: 24px;
          height: 24px;
        }
      }
  }

  &--time{
    font-size: 14px;
    font-weight: 400;
      @include pc{
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin-left: 10px;
      }
      @include tab{
        display: block;
        margin-top: 10px;
      }
  }

}
