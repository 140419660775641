.l-header-11 {
    background: #fff;
    left: 0;
    letter-spacing: .05em;
    position: relative;
    top: 0;
    width: 100%;
    z-index: $z-index-max - 4;

    @include tab {
        padding: 16px 0;
    }

    @include pc {
        min-width: $pc-width;
        width: 100%;
    }

    a {
        transition: opacity .3s;
        color: #231815 !important;
        font-size: 13px;

        @include pc {
            &:hover {
                opacity: .6;
            }
        }
    }

    & > .l-container {
        
        display: flex;
        flex-wrap: wrap;    

        @include tab {
            align-items: center;
            justify-content: space-between;
        }

        @include pc {
            max-width: none;
            padding-right: 26px;
            padding-left: 28px;
        }
    }

    &--left {
        @include pc {
            display: flex;
            flex-wrap: wrap;
            margin-left: 1px;
        }

      
    }

    &--right {
        
        flex-wrap: wrap;

        @include tab {
            align-items: center;
        }

        @include pc {
            margin-left: auto;
            display: none;
        }
    }

    &--logo {
        &--wrapper {
            font-size: 0;
            width: 214px;
            @include pc {
                align-items: center;
                display: flex;
                margin-right: 22px;
                padding: 10px 0;
                
            }
        }

        display: inline-block;

        img {
            display: block;
            height: auto;
            max-width: 100%;
        }
    }

    &--menu {
        &--container {
            @include tab {
                background: #ffffff;
                display: none;
                height: 100%;
                left: 0;
                min-height: 100vh;
                overflow: scroll;
                overflow-x: hidden;
                padding: 24px 0 90px;
                top: 82px;
                width: 100%;
                z-index: $z-index-max - 5;
                -webkit-overflow-scrolling: touch;
            }

            @include pc {
                margin-left: auto;
            }

            .menu {
                margin: 0;
                padding: 0;

                @include pc {
                    flex-wrap: wrap;
                }
            }

            .menu-item {
                @include tab {
                    background: #efefef;
                }
                a {
                    color: #0F78C3;
                    display: block;
                    font-size: 16px;
                    font-weight: bold;
                    padding: 24px 16px;
                    text-decoration: none;


                    @include tab {
                        line-height: 1.6;
                    }

                    @include pc {
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 1.2;
                        padding: 32px 20px 31px;
                        position: relative;
                    }
                }

                & > a {
                    background: #fff;
                }

                &:not(:last-child) {
                    @include tab {
                        border-bottom: 1px solid #E6E6E6;
                    }
                }

                &:last-child {
                    .sub-menu {
                        @include pc {
                            left: auto;
                            right: 0;
                        }
                    }
                }
                a{
                    &::after {
                        transform-origin: right top;
                        transform: scale(0, 1);
                        transition: transform 0.3s;
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 5%;
                        width: 90%;
                        height: 3px;
                        background: #231815;
                    }
    
                    &:hover {
                        &::after {
                            transform-origin: left top;
                            transform: scale(1, 1);
                        }
                    }
                }
            }

            .menu-item-has-children {
                @include pc {
                    position: relative;
                }

                &:before {
                    @include pc {
                        background: center / cover no-repeat url("../img/header/icon_arrow-bottom-blue.svg");
                        content: '';
                        height: 5px;
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        transform-origin: center;  
                        transition: transform .3s;
                        width: 10px;
                        z-index: 1;
                    }
                }

                & > a {
                    @include tab {
                        position: relative;
                        width: calc(100% - 70px);
                    }

                    &:before {
                        @include tab {
                            background: center / cover no-repeat url("../img/header/icon_arrow-bottom-blue.svg");
                            content: '';
                            height: 5px;
                            pointer-events: none;
                            position: absolute;
                            right: -42px;
                            top: 50%;
                            transform: translateY(-50%);
                            transform-origin: center;
                            transition: transform .3s;
                            width: 10px;
                        }
                    }
                }

                &.js-active {
                    & > a {
                    &:before {
                        @include tab {
                            transform: translateY(-50%) rotate(180deg);
                            
                        }
                    }
                    }
                }
                & > a {
                    
                }

                &:hover {
                    &:before {
                        @include pc {
                            transform: translateY(-50%) rotate(180deg);
                        } 
                    }
                    .sub-menu {
                        @include pc {
                            display: block !important;
                        }
                    }
                }
            }

            .sub-menu {
                list-style: none;
                display: none;
                padding: 0;
                cursor: pointer;

                @include pc {
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 220px;
                    padding: 0;
                    z-index: 1;
                }

                a {
                    @include tab {
                        font-weight: normal;
                        padding-right: 75px;
                        padding-left: 48px;
                    }
                }

                .menu-item {
                    @include tab {
                        border-bottom: none;
                    }

                    @include pc {
                        display: block;
                        margin: 0;
                        min-width: 160px;
                    }

                    &:not(:last-child) {
                        @include pc {
                            border-bottom: 1px solid #E6E6E6;
                        }
                    }

                    a {
                        @include pc {
                            background: #ffffff;
                            display: block;
                            width: 100%;
                            height: 100%;
                            padding: 15px;

                            @media all and (-ms-high-contrast: none){
                                display: inline-block;
                            }

                            br, span {
                                display: none;
                            }
                        }

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &--contact {
        &--list {
            display: none;


            list-style: none;
            margin: 0;
            padding: 0;

            @include tab {
                margin-bottom: 100px;
            }
            
            @include pc {
                display: flex;
                flex-wrap: wrap;
                height: 100%;
                @media all and (-ms-high-contrast: none) {
                    height: 70px;
                    overflow: hidden;
                }
            }
        }
        &--item {
            @include pc {
                align-items: center;
                display: flex;
                padding: 10px 0;
            }
            a {
                align-items: center;
                display: flex;
                font-size: 16px;
                font-weight: bold;
                padding: 24px 16px;
                text-decoration: none;


                @include tab {
                    line-height: 1.6;
                    width: 100%;

                    @media all and (-ms-high-contrast: none) {
                        height: 73px;
                    }
                }

                @include pc {
                    border-radius: 25px;
                    font-size: 14px;
                    justify-content: center;
                    line-height: 1.4;
                    min-height: 50px;
                    min-width: 213px;
                    padding: 12px;
                    text-align: center;
                    @media all and (-ms-high-contrast: none) {
                        height: 50px;
                        padding-top: 18px;
                    }
                }
            }

            svg {
                height: auto;
                margin-right: 9px;
                width: 17px;

                @media all and (-ms-high-contrast: none) {
                    position: relative;
                    top: -3px;
                }

                @include tab {
                    width: 17px;
                }
            }

            &:not(:last-child) {
                @include pc {
                    margin-right: 6px;
                }
            }

            &-download {
                a {
                    background: #ffffff;
                    border: 2px solid #37C9E6;
                    color: #37C9E6;
                    
                    @include tab {
                        padding: 22px 16px;
                    }

                    @include pc {
                        padding: 11px;

                        @media all and (-ms-high-contrast: none) {
                            padding-top: 18px;
                        }
                    }
                }
            }

            &-mail {
                a {
                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4be3cf+0,32c3ec+100 */
                    background: #4be3cf; /* Old browsers */
                    background: -moz-linear-gradient(left,  #4be3cf 0%, #32c3ec 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(left,  #4be3cf 0%,#32c3ec 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to right,  #4be3cf 0%,#32c3ec 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4be3cf', endColorstr='#32c3ec',GradientType=1 ); /* IE6-9 */
                    color: #ffffff;
                }

                svg {
                    @include pc {
                        width: 18px;
                    }
                }
            }
        }
    }

    &--hamburger {
        background: #0F78C3;
        position: relative;
        display: inline-block;
        text-decoration: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        cursor: pointer;

        width: 50px;
        height: 50px;
        margin: auto;
        outline: none;
        -webkit-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
        
        &--line {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 30px;
            height: 2px;
            background-color: #fff;
            -webkit-transition: inherit;
                    transition: inherit;

            &:before,
            &:after {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background-color: inherit;
                content: '';
                -webkit-transition: inherit;
                        transition: inherit;
            }

            &:before {
                top: -10px;
            }

            &:after {
                top: 10px;
            }
        }

        &--text-hidden {
            position: absolute;
            white-space: nowrap;
            width: 1px;
            height: 1px;
            overflow: hidden;
            border: 0;
            padding: 0;
            clip: rect(0 0 0 0);
            clip-path: inset(50%); 
            margin: -1px;
        }

        &[aria-expanded="true"] {
            .l-header-11--hamburger--line {
                background: transparent;
                &:before,
                &:after {
                    top: 0;
                    background-color: #fff;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}