.p-single-3{

  &--title{
    font-size: 3.2rem;
    text-align: center;
    height: 30px;
      &-en{
        font-size: 70px;
        text-transform: uppercase;
        display: block;
        color: #FFF;
        margin-top: 5px;
        position: relative;
        z-index: -1;
        top: -120px;
        -webkit-text-stroke: 1px #e0e0e0;
      }
  }



  &--main-contents{
    margin-bottom: 48px;
    @include pc{
      margin-bottom: 96px;
    }
    .l-container{
      @include pc{
        @include container-max-width(900px);
      }
    }
  }
  &--header-wrapper{
    margin-bottom: 12px;
    padding-bottom: 20px;
    border-bottom: 4px dashed #E6E6E6;
    @include pc{
      margin-bottom: 35px;
    }
  }
  &--heading{
    text-align: center;
    padding-bottom: 8px;
    margin: 60px 0 12px 0;
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.75;
    @include pc{
      margin: 60px 0 12px 0;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
  }
  &--header--info{
    padding: 0  13px;
    display: flex;
    @include pc {
      
      justify-content: center;
      align-items: baseline;
      flex-wrap: wrap;
      padding: 0 26px;
    }
    a{
      display: inline-block;
    padding: 5px;
    margin-right: 20px;
    height: 100%;
    font-size: 10px;
    color: #231815;
    letter-spacing: 0.05em;
    line-height: 1.2;
    text-decoration: none;
    border: 1px solid #707070;
    text-align: center;
    font-weight: bold;
    @include pc{
      width: 12%;
    }
    }
  }
  &--category--list{
    display: flex;
    flex-wrap: wrap;
    margin:10px 0 0;
  }
  &--category--item{
    border-radius: 1px;
    margin-bottom: 10px;
    border: 1px solid #707070;
    margin-right: 20px;
    &:not(:last-of-type){
      margin-right: 10px;
    }
    a{
      display: block;
      padding: 5px 25px;
      width: 100%;
      height: 100%;
      font-size: 10px;
      color: #231815;
      letter-spacing: 0.05em;
      line-height: 1.2;
      text-decoration: none;
      border: none;
      margin-right: 0;
      font-weight: bold;
    }
  }
  &--date--list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &--date--item{
    font-size: 14px;
    &:not(:last-of-type){
      padding-right: 13px;
    }
  }

  &--content{
    max-width: 900px;
    margin: auto;
    figure{
      margin-bottom: 16px;
      @include pc {
        margin-bottom: 32px;
      }
      img{
        width: 100%;
      }
    }
    p{
      max-width: 900px;
      margin: 0 auto 25px;
      padding: 0 10px;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 1.8;
      @include pc{
        margin-bottom: 40px;
        padding: 0;
      }
    }
    h2{
      margin-bottom: 24px;
      padding: 5px 8px ;
      // border-left: 2px solid #000;
      // background: #E8E8E8;
      font-size: 2.2rem;
      font-weight: 700;
      letter-spacing: 0.05em;
      line-height: 1.4;
      @include pc {
        margin-bottom: 40px;
        padding: 5px 0px;
      }
    }
    h3{
      display: flex;
      margin-bottom: 20px;
      font-size: 2.0rem;
      letter-spacing: 0.05em;
      line-height: 1.4;
      @include pc{
        margin-bottom: 40px;
      }
      &::before{
        margin-top: 12px;
        margin-right: 7px;
        content: "";
        display: block;
        width: 100%;
        max-width: 10px;
        height: 2px;
        background: #AAAAAA;
      }
    }

    .wp-block-embed-youtube {
      height: 0;
      overflow: hidden;
      padding-top: 30px;
      padding-bottom: 56.25%;
      position: relative;

      .wp-block-embed__wrapper{
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      iframe,
      object,
      embed {
        height: 100%;
        width: 100%;
      }
    }

    //2つのカラム画像
    .wp-block-column{
      figure{
        @include pc{
          height: 246px;
        }
        img{
          @include pc{
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .c-button-3{
    margin-bottom: 90px;
  }
}

.single-work{
  margin-bottom: 100px;
  background-color: #F5F5F5;
}
