


.c-button-3{
  display: flex;
  margin-top: 50px;
  justify-content: center;
  a{
    font-family: "ヒラギノ角ゴシック";
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 14px 5px;
    // padding: .5em 4em;
    color: #FFF;
    background-color: #231815;
    text-align: center;
    text-decoration: none;
    transition: .3s;
    border: 1px solid;
    letter-spacing: 0;
    @include pc{
       max-width: 203px;
     }
    &:hover{
      color: #231815;
      background-color: #FFF;
      position: relative;
      z-index: 1;
      border: 1px solid #231815;
    }
    &::before{
      position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: -1;
     content: '';
     background: #FFF;
     transform-origin: right top;
     transform: scale(0, 1);
     transition: transform .3s;
    }
    &:hover::before{
      transform-origin: left top;
      transform: scale(1, 1);
    }
    &:after{
      content: "";
    background-image: url(../img/other/btn-arrow.png);
    width: 10px;
    height: 3px;
    position: absolute;
    top: 24px;
    right: 15px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    }
    &:hover::after{
      background-image: url(../img/other/btn-arrow-bl.png);
    }
  }
  
}



.c-btn {
  font-size: 14px;
  text-align: center;
  color: #333;
  text-decoration: none;
  border-bottom: 0.5px solid #333;
  padding-bottom: 5px;
  &:after{
    content: "";
    background-image: url(../img/other/btn-arrow-bl.png);
    background-size: cover;
    width: 10px;
    height: 3px;
    display: inline-block;
    position: relative;
    top: -5px;
    right: -5px;
  }
  &:hover{
    opacity: 0.6;
  }
}