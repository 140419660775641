/*

Styling for form

*/

.c-form {
  dl,
  dt,
  dd {
    margin: 0;
  }
  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  label,
  input,
  textarea {
    display: block;
  }

  input,
  textarea,
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    padding: 0.4em 0.8em;
    width: 100%;

    &:focus {
      outline: 1px #4D68A6 solid;
    }
  }

  select {
    background: #fff url() no-repeat center right 8px/16px 16px;

    &::-ms-expand {
      display: none;
    }
  }

  input[type=checkbox],
  input[type=radio]{
    position: absolute;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    // clip-path: inset(50%);
    margin: -1px;

    & + span {
      cursor: pointer;
      display: inline-block;
      padding: 0 0 0 30px;
      position: relative;

      &:before,
      &:after {
          content: "";
          display: block;
          left: 0;
          position: absolute;
      }

      &:before {
        -webkit-transform: translateY(-50%);
        background: #fff;
        border: 1px solid #D6D6D6;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }

      &:after {
        opacity: 0;
        top: 50%;
        transition: all 0.3s ease 0s;
      }
    }

    &:focus {
      & + span {
        &:before {
          outline: 1px #4D68A6 solid;
        }
      }
    }

    &:checked {
      & + span {
        &:after {
          opacity: 1;
        }
      }
    }

    &.js-ie + span {
      &:before,
      &:after {
        top: 40%;
      }
    }
  }

  input[type=checkbox] + span {
    &:before {
      border-radius: 2px;
    }

    &:after {
      -webkit-transform: translateY(-50%) rotate(-45deg);
      border-bottom: 3px solid #F43838;
      border-left: 3px solid #F43838;
      height: 12px;
      margin-top: -0.2em;
      transform: translateY(-50%) rotate(-45deg);
      width: 20px;
    }
  }

  input[type=radio] + span {
    &:before {
      border-radius: 50%;
    }

    &:after {
      -webkit-transform: translateY(-50%);
      background: #F43838;
      border: 1px solid transparent;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      height: 8px;
      left: 6px;
      width: 8px;
    }
  }

  input[type=submit] {
    -webkit-appearance: none;
    background-color: rgba(0, 0, 0, 0.32);
    background-image: none;
    border: none;
    border-radius: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin: 0 0 1em;
    padding: 0.6em 2em;
    text-decoration: none;
    transition:
      border .3s,
      opacity .3s;

    &:disabled {
        opacity: .6;
    }
  }

  .c-form--item--heading {
    p,label {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  }

  .c-form--item-notice a {
    transition: opacity .3s;
    
    @include pc {
        &:hover {
            opacity: .6;
        }
    }

    &:focus {
        opacity: .6;
    }

    &:visited {
        color: #80006a;
    }
  }

  /*contact form 7*/
  .wpcf7-not-valid-tip {
    margin: 5px 0;
  }

  span.wpcf7-list-item {
    display: block;
    margin: 20px 0;

    @include pc {
        margin: 10px 0;
    }

    &.first {
        margin-top: 0;
    }

    &.last {
        margin-bottom: 0;
    }
  } 
}