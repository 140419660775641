*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

html {
  font-size: 52%;


  @include pc {
    font-size: 62.5%;
  }

  &.js-scroll-prevent {
    overflow: hidden;
  }
}

body{
  letter-spacing: 0.07em;
  // font-family: $main-font;
  font-size: 16px;
  width: 100%;

  //共通箇所追記
  line-height: 1.75;
  color: #231815;
  font-family: "Helvetica Neue";

  @include tab {
    overflow-x: hidden;
  }

  @include pc{
    width: 100%;
    min-width: $pc-width;
  }
}

h1 {
  margin: 0;
  font-size: 28px;
  font-weight: bold;
}
h2{
  font-size: 24px;
  font-weight: bold;
}
h3{
  font-size: 20px;
  font-weight: bold;
}
h4{
  font-size: 18px;
}

p{
  font-size: 15px;
  line-height: 1.75;
}

iframe,
img,
svg {
  height: auto;
  max-width: 100%;
}


b, strong {
  font-weight: bold;
}

iframe,
img {
  vertical-align: bottom;
}

dl{
  margin: 0;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.menu{
  list-style: none;
  padding-left: 0;
  @include pc{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: flex-start;
  }
}

.inner-container{
  @include pc{
    @include contents;
    margin-bottom: 80px;
  }
}

.sub-container{
  @include tab{
    padding: 0 20px;
  }
}

.single-contents h2{
  letter-spacing: 2px;
  margin-top: 45px;
  margin-bottom: 15px;
  border-top: solid 5px $main-color;
  border-bottom: solid 5px $main-color;
  padding: 10px;
  @include pc{
    font-size: 28px;
    line-height: 1.8;
  }
  @include tab{
    font-size: 24px;
    line-height: 1.6;
  }
}

.single-contents h3{
  letter-spacing: 2px;
  margin-top: 45px;
  margin-bottom: 15px;
  border-left: solid 4px $main-color;
  padding: 10px;
    @include pc{
      font-size: 24px;
      line-height: 1.8;
    }
    @include tab{
      font-size: 20px;
      line-height: 1.6;
    }
}

.sub-contents{
  @include pc{
    width: 714px;
    margin-right: 40px;
  }
}

.works-contents h2{
  letter-spacing: 2px;
  margin-top: 45px;
  margin-bottom: 15px;
  border-top: solid 5px $main-color;
  border-bottom: solid 5px $main-color;
  padding: 10px;
  @include pc{
    font-size: 28px;
    line-height: 1.8;
  }
  @include tab{
    font-size: 24px;
    line-height: 1.6;
  }
}

.works-contents h3{
  letter-spacing: 2px;
  margin-top: 45px;
  margin-bottom: 15px;
  border-left: solid 4px $main-color;
  padding: 10px;
    @include pc{
      font-size: 24px;
      line-height: 1.8;
    }
    @include tab{
      font-size: 20px;
      line-height: 1.6;
    }
}

.sub-contents img{
  margin-bottom: 20px;
}

.thumbnail img{
  width: 100%;
  height: auto;
}

.flex-wrap{
  @include pc{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    flex-wrap: wrap;
  }
}

.list-none{
  @include list-none;
}

.list-post{
  width: 100%;
  list-style: none;
}

.list-post li{
  width: 33.333333%;
  list-style: none;
}

.list-thumbnail img{
  width: 100%;
  height: auto;
}

.keyword {
  text-decoration: none;
  text-align: left;
  padding-bottom: 10px;
  span{
    text-decoration: none;
    color: $font-color;
    padding: 5px;
    font-weight: 400;
    font-size: 14px;
    margin-right: 5px;
  }
  time{
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
    border-left: solid 1px;
  }
}
