.p-service-1{
  padding-top: 0px;
  padding-bottom: 100px;
  overflow: hidden;

  p{
    letter-spacing: .05em;
    text-align: center;
  }

  &--title{
    font-size: 3.2rem;
    text-align: center;
    height: 30px;
      &-en{
        font-size: 70px;
        text-transform: uppercase;
        display: block;
        color: #FFF;
        margin-top: 5px;
        position: relative;
        z-index: -1;
        top: -120px;
        -webkit-text-stroke: 1px #e0e0e0;
      }
  }

  &--catch{
    margin-top:60px;
    margin-bottom: 120px;
    p{
      @include pc{
        width: 900px;
        margin: 0 auto;
      }
    }
  }
  //service-1新規追加
  &--content--list{
    .p-service-1--list{
      display: flex;
      flex-wrap: wrap;
    }
    .p-service-1--item{
      position: relative;
      text-align: center;
      &--figure{
        width: 100%;
        height: 260px;
        img{
          width: 100%;
          height: 100%;
          border-radius: 20px;
          object-fit: cover;
        }
      }
      &:not(:nth-of-type(3n)) {
        margin-bottom: 50px;
        @include pc{
          margin-right: 45px;
          margin-bottom: 0;
        }
      }
      @include pc{
        width: calc((100% - 45px * 2) / 3);
        padding-bottom: 30px;
      }
      
      &--title{
        display: inline-block;
        font-size: 24px;
        background-color: #FFF;
        padding: 15px 20px 0 20px;
        border-radius: 10px 10px 0 0;
        position: relative;
        top: -39px;
        // right: 0;
        // left: 0;
        text-align: center;
        line-height: 1;
      }
      &--text{
        font-size: 15px;
        line-height: 2;
        text-align: center;
        margin-bottom: 30px;
      }
      a{
        text-decoration: none;
        color: inherit;
        display: block;
      }
      .c-btn{
        text-align: center;
        color: #333;
        text-decoration: none;
        border-bottom: 0.5px solid #CCC;
        width: 25%;
        margin: 0 auto;
        display: unset;
      }
      
      &:hover{
        box-shadow: 3px 6px 10px gray;
        border-radius: 20px;
        transition: 0.5s;
      }
    }
  }
}






  

  



