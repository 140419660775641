.p-archive-7{
  padding-bottom: 40px;
  @include pc{
    padding-bottom: 90px;  //デザイン上の余白 - .page-numbers or button--wrapのmargin-bottom
  }
    
    &--title{
      font-size: 3.2rem;
      text-align: center;
      height: 30px;
        &-en{
          font-size: 70px;
          text-transform: uppercase;
          display: block;
          color: #FFF;
          margin-top: 5px;
          position: relative;
          z-index: -1;
          top: -100px;
          -webkit-text-stroke: 1px #e0e0e0;
          line-height: 1;
          @include pc{
            top: -120px;
            line-height: 1.75;
          }
        }
    }
  &--tab{
    margin-top: 60px;
  }
  .l-container{
    @include pc{
      max-width: calc( 1400px + 5% * 2);

    }
  }

  &--category-list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 19px;
    justify-content: center;
    @include pc{
      margin: 60px 0 13px 0;
      border-bottom: 1px solid #AAAAAA;
    }
  }

  &--category--item{
    font-size: 14px;
    letter-spacing: 0.05em;
    position: relative;
    @include tab {
      width: calc(50% - 12px);
    }

    @include pc {
      margin-bottom: -2px;
      margin-right: 5px;
    }

    &:nth-of-type(odd) {
      @include tab {
        margin-right: 12px;
      }
    }

    &:nth-of-type(n + 3) {
      @include tab {
        margin-top: 12px;
      }
    }
    
  }

  //タブの通常状態
  &--category--item--inner {
    font-size: 14px;
    width: 100%;
    height: 100%;
    padding: 9px 6px 20px 6px;
    color: #000;
    height: 100%;
    letter-spacing: 0.08em;
    border-bottom: 3px solid #231815;
    @include pc {
      padding: 20px 0;
      width: 120px;
    }
    &::after {
      content: "";
      background-image: url(../img/other/arrow.png);
      background-repeat: no-repeat;
      width: 8px;
      height: 4px;
      display: inline-block;
      background-size: contain;
      margin: 0 auto;
      position: absolute;
      bottom: 12px;
      right: 0;
      left: 0;
    }
    &:hover,
    &:focus,
    &[aria-selected="true"] { //アクティブ状態
      font-weight: bold;
      border-bottom: 3px solid #FFD72B;
      &::after{
        content: "";
      background-image: url(../img/other/arrow-weight.png);
      background-repeat: no-repeat;
      width: 10px;
      height: 6px;
      display: inline-block;
      background-size: contain;
      margin: 0 auto;
      position: absolute;
      bottom: 12px;
      right: 0;
      left: 0;
      }
    }
  }

  //コンテンツの通常状態
  &--content {
      display: none;
      a{
        color: #000;
        text-decoration: none;
      }
      &[aria-hidden="false"] { //アクティブ状態
          display: block;
          animation: fade_ani 1s ease 0s;
      }
  }

  &--list,
  &--pager .page-numbers,
  &--button--wrap,
  &--sidebar--wrap {
    margin-bottom: 10px;
  }

  &--list{
    display: flex;
    flex-wrap: wrap;
  }

  &--item{
    width: 100%;
    margin-bottom: 45px;
    @include pc{
      margin-bottom: 0;
      width: calc((100% - 40px * 2) / 3);
      &:nth-of-type(n+4){
        margin-top: 41px;
      }
      &:not(:nth-of-type(3n)){
        margin-right: 40px;
      }
    }
    &:last-of-type{
      margin-bottom: 0;
    }

    a {
      display: block;
      height: 100%;
    }

    &--figure{
      width: 100%;
      height: 70%;
      margin-bottom: 10px;
      border-radius: 5px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    &--info{
      display: flex;
      align-items: baseline;
      margin-bottom: 16px;
    }
    &-date{
      margin-right: 13px;
      font-size: 12px;
      letter-spacing: 0.05em;
    }
    &--category-list{
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      flex: 1;
    }
    &--category-item{
      margin-top: 11px;
      margin-right: 8px;
      padding: 5px 8px;
      border: 1px solid #000;
      border-radius: 3px;
      font-size: 12px;
      word-break: break-all;
    }
    &--heading{
      width: 100%;
      padding-left: 26px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 1.6;
      position: relative;
      svg{
        left: 0;
        position: absolute;
        top: 2px;
      }
    }
    &--info{
      display: flex;
    }
  }

  &--pager,
  &--button--wrap {
    margin-top: 30px;

    @include pc {
      margin-top: 60px;
    }
  }

  &--button {
      &--wrap {
        text-align: center;
      }
      background: #E3E3E3;
      border-radius: 3px;
      color: #000;
      display: inline-block;
      letter-spacing: 0.05em;
      line-height: 1.3;
      max-width: 320px;
      text-decoration: none;
      text-align: center;
      padding: 9px 16px;
      width: 100%;
  }
  //タブアニメーションの定義
  @keyframes fade_ani {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
  }

  &--pager{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .page-numbers{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 37px;
      min-height: 37px;
      margin-right: 5px;
      margin-left: 5px;
      padding: 2px 0;
      border: 1px solid #777777;
      border-radius: 3px;
      color: #000;
      font-size: 16px;
      text-align: center;
      text-decoration: none;

      &.current{
        border: none;
        color: #fff;
        background: #000;
      }
      &.dots{
        margin: 0 7px;
        border: none;
      }
      &.next,&.prev{
        width: auto;
        height: auto;
        border: none;
        font-size: 12px;
        font-weight: 700;
        background: transparent;
        position: relative;
        text-align: center;
        letter-spacing: 0;

        &::before{
          transform: rotate(45deg) translateY(-50%);
          position: absolute;
          content: '';
          width: 6px;
          height: 6px;
          top: 50%;
        }
      }

      &.next{
        margin-left: 10px;
        padding-right: 16px;

        &::before{
          border-top: solid 2px #000;
          border-right: solid 2px #000;
          right: 3px;
        }
      }
      &.prev{
        margin-right: 10px;
        padding-left: 16px;

        &::before{
          border-bottom: solid 2px #000;
          border-left: solid 2px #000;
          left: 0;
        }
      }
    }
  }


  //記事一覧部分のコード追記
  .p-service-11--content--list{
    padding-bottom: 60px;
    .p-service-11--list--title{
      font-size: 32px;
      text-align: center;
      padding: 40px 0;
    }
    .p-service-11--list{
      display: flex;
      flex-wrap: wrap;
      font-family: "ヒラギノ角ゴシック";
    }
    .p-service-11--item{
      position: relative;
      text-align: left;
      margin-top: 47px;
      @include pc{
        padding-bottom: 40px;
        
      }
      &--figure{
        width: 100%;
        @include pc{
          height: 218px;
        }
        img{
          height: 100%;
          border-radius: 10px;
        }
      }
      &:not(:nth-of-type(3n)) {
        // margin-bottom: 0;
        @include pc{
          margin-right: 45px;

        }
      }
      @include pc{
        width: calc((100% - 45px * 2) / 3);
        padding-bottom: 80px;
      }
      
      &--title{
        font-size: 15px;
        padding: 20px 15px;
        line-height: 1.53;
      }
      &--tag{
        font-size: 14px;
        width: 28px;
        height: auto;
        background-color: #FFF;
        padding: 8px 5px;
        border-radius: 0 0 5px 5px;
        position: absolute;
        top: 0px;
        left: 15px;
        line-height: 1.42;
        writing-mode: vertical-lr;
        ul{
          padding-top: 0;
          margin: 0;
          list-style: none;
        }
      }
      &--text{
        font-size: 12px;
        color: #AAAAAA;
        line-height: 1.75;
        padding:0 15px;
        margin-bottom: 20px;
        
      }
      .p-service-11--item--text--tag{
        padding: 0 10px;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li{
          font-size: 12px;
          color: #AAAAAA;
          line-height: 1.75;
          padding: 0 5px;
          margin-bottom: 8px;
        }
      }
      a{
        display: block;
      }
      .c-btn{
        margin-left: 15px;
        display: unset;
        @include pc{
          position: absolute;
          bottom: 30px;
        }
      }
      
    }
  }

  .pc-block{
    display: none;
    @include pc{
      display: block;
    }
  }
  .sp-block{
    @include pc{
      display: none;
    }
  }
}
