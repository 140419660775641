.p-archive-4.p-archive-cat-4{
  .p-archive-4--button {
      &--wrap {
        text-align: center;
        margin: 26px 0 10px;

        @include pc {
          margin-top: 51px;
        }

        &-withpagination {
          margin-top: 15px;

          @include pc {
              margin-top: 40px;
          }
        }
      }
      border-radius: 3px;
      display: inline-block;
      letter-spacing: 0.05em;
      line-height: 1.3;
      max-width: 320px;
      text-decoration: none;
      text-align: center;
      padding: 9px 16px;
      width: 100%;
      &:after{
        top: 16px;
      }
  }
}
