.p-price-1{
  padding-top: 0px;
  padding-bottom: 100px;
  overflow: hidden;

  p{
    letter-spacing: .05em;
    text-align: center;
  }

  &--title{
    font-size: 3.2rem;
    text-align: center;
    height: 30px;
      &-en{
        font-size: 70px;
        text-transform: uppercase;
        display: block;
        color: #FFF;
        margin-top: 5px;
        position: relative;
        z-index: -1;
        top: -120px;
        -webkit-text-stroke: 1px #e0e0e0;
      }
  }

  &--catch{
    margin-top:60px;
    position: relative;
    top: 18px;

    ul{
      display: flex;
      justify-content: center;
      padding-left:0;
      border-bottom: 1px solid #E6E6E6;
      li{
        font-size: 14px;
        border-bottom: 3px solid #FFD72B;
        margin-right: 5px;
        list-style: none;
        width: 120px;
        padding-bottom: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        &:nth-child(2){
          border-bottom: 3px solid #E05595;
        }
        &:nth-child(3){
          border-bottom: 3px solid #2B9DFF;
        }
        &::after{
          content: "";
          background-image:url(../img/other/arrow.png);
          background-repeat: no-repeat;
          width: 8px;
          height: 4px;
          display: inline-block;
          background-size: contain;
          margin: 0 auto;
          position: relative;
          top: 3px;
        }
        a{
          text-decoration: none;
          color: #000000;
          display: block;
        }
      }
    }
  }


  &--content--list{
    padding-top: 90px;
    &:last-of-type{
      padding:90px 0;
      .p-price-1--item{
        ul{
          li{
            &::marker{
              color: #2B9DFF;
            }
          }
        }
      }
    }
    &:nth-last-of-type(2){
      .l-container{
        padding: 90px 5%;
        border-top: 4px dashed #E6E6E6;
        border-bottom: 4px dashed #E6E6E6;
      }
      .p-price-1--item{
        ul{
          li{
            &::marker{
              color: #E05595;
            }
          }
        }
      }
    }
    .p-price-1--list--title{
      font-size: 32px;
      text-align: center;
      margin-bottom: 40px;
    }
    .p-price-1--list{
      display: flex;
      flex-wrap: wrap;
    }
    .p-price-1--item{
      padding-bottom: 35px;
      position: relative;
      text-align: center;
      margin-bottom: 50px;
      width: 100%;
      &--figure{
        img{
          width: 100%;
          height: 244px;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      &:not(:nth-of-type(3n)) {
        
        @include pc{
          margin-right: 45px;
          // margin-bottom: 0;
        }
      }
      @include pc{
        width: calc((100% - 45px * 2) / 3);
        padding-bottom: 80px;
      }
      
      &--title{
        font-size: 24px;
        background-color: #FFF;
        padding: 20px 20px 0 20px;
        border-radius: 10px 10px 0 0;
        text-align: center;
        line-height: 1;
      }
      &--price{
        font-size: 45px;
        font-weight: bold;
        border-bottom: 1px solid #707070;
        b{
          font-size: 15px;
          padding-left: 5px;
        }
      }
      &--text{
        font-size: 15px;
        line-height: 2;
        text-align: left;
        margin-bottom: 20px;
        @include pc{
          padding: 0 5%;
        }
      }
      ul{
        text-align: left;
        padding-left: 5%;
        @include pc{
          padding-left: 9%;
        }

        li{
          font-size: 15px;
          &::marker{
            color: #FFD72B;
          }
        }
      }
      .c-btn{
        position: absolute;
        bottom: 0px;
        right: 0;
        left: 0;
        width: 52%;
        margin: auto;
        @include pc{
          bottom: 30px;
          width: 45%;
        }
      }
      a{
        display: block;
        text-decoration: none;
        color: inherit;
      }
      &:hover{
        box-shadow: 3px 6px 10px gray;
        border-radius: 20px;
        transition: 0.5s;
      }
    }
  }
}






  

  



