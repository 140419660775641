.l-footer-5 {
    background: #1F1A19;
    color: #fff;
    font-weight: bold;
    letter-spacing: .05em;
    padding: 50px 0 31px;

    @include pc {
        @media all and (-ms-high-contrast: none){
            overflow: hidden;
        }
    }

    a {
        transition: opacity .3s;

        @include pc {
            &:hover {
                opacity: .6;
            }
        }
    }

    &--upper {
        @include pc {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &--left {
            @include pc {
                margin-right: 30px;
                width: 21.3%;
            }
        }

        &--right {
            @include pc {
                display: flex;
                flex-wrap: wrap;
                padding-right: 12px;
                width: 73%;
            }
        }
    }

    &--lower {
        padding-top: 20px;
    }

    &--logo {
        &--wrapper {
            font-size: 0;
            margin-bottom: 25px;
        }

        display: inline-block;

        img {
            display: block;
            height: auto;
            max-width: 100%;
        }
    }

    &--name {
        line-height: 1.7;
        margin-bottom: 14px;
    }

    &--address {
        &--list {
            margin: 0 0 20px;
            padding: 0;
            list-style:none;
        }

        &--item {
            &:not(:first-child) {
                @include pc {
                    margin-top: 10px;
                }
            }
        }

        font-size: 14px;
        font-style: normal;
        line-height: 1.6;
    }

    &--sns {
        &--list {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            list-style: none;

            @include tab {
                justify-content: center;
                margin-bottom: 30px;
            }
        }

        &--item {
            height: 50px;
            margin-bottom: 10px;
            width: 50px;

            &:not(:last-child) {
                margin-right: 18px;
            }

            a {
                align-items: center;
                background: #3e3e3e;
                display: flex;
                height: 100%;
                justify-content: center;
                padding: 9px;
                width: 100%;
            }
        }
    }

    &--menu-nochildren--container,
    &--menu-haschildren--container {
        ul {
            margin: 0;
        }

        .menu {
            display: block;
        }

        a {
            color: #ffffff;
            font-size: 14px;
            line-height: 1.9;
            text-decoration: none;
        }
    }

    &--menu-nochildren--container {
        @include tab {
            margin-bottom: 20px;
        }
        @include pc {
            margin-right: 30px;
            width: calc((100% - 30px * 3) / 4 );
        }

        .menu-item {
            &:not(:last-child) {
                margin-bottom: 20px;

                @include pc {
                    margin-bottom: 6px;
                }
            }
        }
    }

    &--menu-haschildren--container {
        @include pc {
            display: flex;
            flex:1;
            flex-wrap: wrap;
        }

        .menu {
            @include pc {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }
        }

        .menu-item-has-children {
            @include pc {
                width: calc((100% - 30px * 2) / 3);
            }

            & > a {
                display: inline-block;
                margin-bottom: 15px;

                @include pc {
                    margin-bottom: 6px;
                }
            }

            &:not(:first-child) {
                @include tab {
                    margin-top: 20px;
                }
            }

            &:not(:nth-of-type(3n)) {
                @include pc {
                    margin-right: 30px;
                }
            }

            &:nth-of-type(n + 4) {
                @include pc {
                    margin-top: 30px;
                }
            }
        }

        .sub-menu {
            list-style: none;
            padding: 0;

            .menu-item {
                &:not(:last-child) {
                    margin-bottom: 20px;

                    @include pc {
                        margin-bottom: 6px;
                    }
                }
            }

            a {
                display: inline-block;
                font-weight: normal;
                position: relative;
                padding-left: 15px;

                &:before {
                    content: '-';
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }

    &--copyright {
        font-size: 14px;
        font-weight: normal;
    }
}

//お問い合わせのフッター部分
.l-footer-contact{
    padding: 0;
    text-align: center;
    .l-footer-5--lower{
        padding:20px;
    }
    .l-footer-5--copyright{
        font-size: 12px;
        font-weight: normal;
        display: grid;
    }
}