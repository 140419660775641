.p-archive-7.p-archive-cat-7{
  .p-archive-7--content{
    display: block;
  }
  .p-archive-7--button--wrap-withpagination{
    margin-top: 15px;
    @include pc{
      margin-top: 40px;
    }
  }
}
