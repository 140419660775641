.p-contact-2 {
    a {
        transition: opacity .3s;

        @include pc {
            &:hover {
                opacity: .6;
            }
        }
    }

    .l-container {
        @include pc {
            @include container-max-width(700px);
        }
    }

    &--heading {
        &--wrapper {
            padding: 71px 0 24px;
        }

        color: #231815;
        font-size: 3.2rem;
        font-weight: bold;
        line-height: 1.7;
    }

    &--catch {
        padding: 0 0 27px;

        p {
            font-size: 14px;
            line-height: 1.7;
        }
    }

    &--form--wrapper {
        padding-bottom: 80px;

        .c-form {
            &--item {
                background: #fafafa;
                border: 1px solid #ddd;
                
                @include tab {
                    padding: 15px 10px;
                }

                @include pc {
                    display: flex;
                    flex-wrap: wrap;
                }

                input,
                textarea,
                select {
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    padding: 7px 5px;
                    width: 100%;

                    @include pc {
                        padding-right: 5px;
                        padding-left: 5px;
                    }
                }

                input {
                    @include pc {
                        max-width: 280px;
                    }
                }

                textarea {
                    height: 160px;
                }

                select {
                    background-image: url(../img/contact/contact-2_icon_arrow.svg);
                    background-position: center right 5px;
                    background-repeat: no-repeat;

                    @-moz-document url-prefix() {
                        padding-top: 7px;
                        padding-bottom: 7px;
                    }

                    @include pc {
                        background-position: center right 10px;
                    }
                }
            }

            &--item--left {
                @include tab {
                    margin-bottom: 10px;
                }
                @include pc {
                    padding: 20px;
                    width: 37%;
                }
            } 

            &--item--right {
                @include pc {
                    padding: 15px 20px;
                    width: 63%;
                }
            }

            &--item--heading {
                color: #333;
                font-size: 14px;
                line-height: 1.7;

                &--required {
                    background: #CD0000;
                    color: #fff;
                    display: inline-block;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.7;
                    margin-left: 12px;
                    padding: 0 7px;
                    text-align: center;
                }
            }

            &--item--explanation {
                margin-top: 3px;

                ul {
                    margin: 0;
                    padding-left: 26px;
                }

                p,
                li {
                    color: #B2B2B2;
                    font-size: 14px;
                    line-height: 1.7;
                }
            }

            &--item--field--heading {
                p,li,label {
                    color: #333333;
                    font-size: 14px;
                    line-height: 1.7;
                }
            }

            &--item--list {
                @include pc {
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            &--item--item {
                @include pc {
                    max-width: 110px;
                    width: calc((100% - 10px) / 2);
                }

                &:nth-of-type(odd) {
                    @include pc {
                        margin-right: 10px;
                    }
                }
            }

            &--item-notice,
            &--item-submit {
                border: none;
                padding: 0;
            }

            &--item-notice {
                background: #ffffff;
                display: block;
                font-size: 12px;
                margin-bottom: 30px;

                a {
                    color: #4d78c6;
                }
            }

            &--item-submit {
                background: #ffffff;
                margin: 40px 0 0;

                // input[type="submit"] {
                //     background: center right 20px/ 8px  13px no-repeat #4D78C6 url(../img/contact/contact-2_icon_arrow-right.svg);
                //     box-shadow: 0 0 20px rgba(0, 0, 0, .2);
                //     border: none;
                //     border-radius: 35px;
                //     color: #ffffff;
                //     display: block;
                //     font-size: 1.8rem;
                //     font-weight: bold;
                //     line-height: 1.5;
                //     margin: 0 auto;
                //     max-width: 360px;
                //     padding: 19px 45px;
                //     width: 100%;

                // }
            }
        }
    }

    .c-button-3{
        display: flex;
        flex-direction: column;
        input{
            font-family: "ヒラギノ角ゴシック";
        
            font-size: 14px;
            font-weight: 600;
            position: relative;
            display: inline-block;
            // padding: .5em 4em;
            color: #FFF;
            background-color: #231815;
            text-align: center;
            text-decoration: none;
            transition: .3s;
            margin: 0 auto;
            height: 50px;
        
            @include pc{
            //    max-width: 250px;
             }
            &:hover{
              color: #231815;
              background-color: #FFF;
              position: relative;
              z-index: 1;
              border: 1px solid #231815;
            }
            &::before{
              position: absolute;
             top: 0;
             right: 0;
             bottom: 0;
             left: 0;
             z-index: -1;
             content: '';
             background: #FFF;
             transform-origin: right top;
             transform: scale(0, 1);
             transition: transform .3s;
            }
            &:hover::before{
              transform-origin: left top;
              transform: scale(1, 1);
            }
            &:after{
              content: "";
            background-image: url(../img/other/btn-arrow.png);
            width: 10px;
            height: 3px;
            position: absolute;
            top: 24px;
            right: 15px;
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            }
        }
    }
}

//お問い合わせジャンル部分
.c-form input[type=checkbox]+span, .c-form input[type=radio]+span{
    font-size: 14px;
}
.c-form span.wpcf7-list-item.first{
    @include pc {
       margin-top: 10px;
    }
}
span.wpcf7-form-control.wpcf7-checkbox.wpcf7-validates-as-required{
    @include pc {
        display: flex;
        flex-wrap: wrap;
     }
}
.wpcf7-list-item{
    @include pc {
        width: 45%;
     }
}

/*safariのみに適用*/
::-webkit-full-page-media, :future, :root .p-contact-2 .p-contact-2--form--wrapper .c-form--item select {
    padding-top: 10px;
    padding-bottom: 10px;
}

