.p-front-1{
  padding-top: 0px;
  padding-bottom: 100px;
  overflow: hidden;

  p{
    letter-spacing: .05em;
    // text-align: center;
  }
  .pc-block{
    display: none;
    @include pc{
      display: block;
    }
  }
  .sp-block{
    @include pc{
      display: none;
    }
  }

  &--title{
    font-size: 3.2rem;
    text-align: center;
    height: 100px;
      &-en{
        font-size: 70px;
        text-transform: uppercase;
        display: block;
        color: #FFF;
        margin-top: 5px;
        position: relative;
        z-index: -1;
        top: -120px;
        -webkit-text-stroke: 1px #e0e0e0;
      }
  }

  
  &--fv{
    position: relative;
    &--figure{
      width: 90%;
      height: 400px;
      @include pc{
        height: unset;
      }
      img{
        width: 100%;
        border-radius: 0 10px 10px 0;
        height: 100%;
        object-fit: cover;
      }
      
    }
    .l-container{
      position: relative;
      text-align: right;
      top: -150px;
      @include pc{
        position: absolute;
      right: 0;
      top: 30%;
      }
    }
    &--title{
      background-color: #FFF;
      font-size: 20px;
      line-height: 2;
      padding: 10px;
      display: initial;
      position: relative;
      top: -100px;
      @include pc{
        font-size: 40px;
        top: unset;
      }
    }
    &--text{
      background-color: #FFF;
      font-size: 12px;
      font-weight: bold;
      display: inline-block;
      border-radius: 5px;
      margin-top: 15px;
      padding: 15px;
      text-align: left;
      @include pc{
        font-size: 15px;
        width: 39%;
      }
    }
    &--btn{
      @include pc{
        display: flex;
        position: absolute;
        right: 75px;
        top: 350px;
      }
      &--tel{
        width: 100%;
        height: 50px;
        text-align: center;
        background-color: #231815;
        color: #FFF;
        padding: 8px;
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        @include pc{
          margin-right: 8px;
          width: 200px;
        }
        &--title{
          font-size: 10px;
          line-height: 1;
        }
        &--number{
          font-size: 18px;
          font-weight: bold;
          b{
            font-size: 10px;
          }
        }
      }
      .c-button-3{
        width: 100%;
        height: 50px;
        margin-top: 0;
        @include pc{
          width: 200px;
        }
        a{
          border: 1px solid #231815;
        }
      }
    }  
  }

  &--service{
    @include pc{
      margin-top: 70px;
      .p-front-1--title{
        text-align: left;
      }
    }
    &--list{
      .p-front-1--list{
        display: flex;
        flex-wrap: wrap;
      }
      .p-front-1--item{
        position: relative;
        text-align: center;
        &--figure{
          img{
            width: 100%;
            height: 460px;
            object-fit: cover;
            border-radius: 20px;
            @include pc{
              height: 96%;
            }
          }
        }
        &:not(:nth-of-type(3n)) {
          margin-bottom: 50px;
          @include pc{
            margin-right: 45px;
            margin-bottom: 0;
          }
        }
        @include pc{
          width: calc((100% - 45px * 2) / 3);
        }
        &--figure{
          // 
          @include pc{
            height: 460px;
          }
          
        }
        &--title{
          display: inline-block;
          font-size: 24px;
          background-color: #FFF;
          padding: 20px;
          border-radius: 10px 10px 0 0;
          position: relative;
          top: -89px;
          text-align: right;
          line-height: 1.5;
          writing-mode: vertical-rl;
          
        }
        &:nth-child(2){
          .p-front-1--item--title{
            top: -140px;
          }
          .p-front-1--item--text{
            margin-top: -120px;
            @include pc{
              margin-top: 0px;
            }
          }

        
        }
        &:nth-child(3){
          .p-front-1--item--title{
            top: -140px;
          }
          .p-front-1--item--text{
            margin-top: -120px;
            @include pc{
              margin-top: 0px;
            }
          }

        
        }
        &--text{
          font-size: 15px;
          line-height: 2;
          margin-top: -70px;
          @include pc{
            margin-bottom: 30px;
            text-align: center;
            margin-top: 0;
          }
        }
        a{
          display: block;
          text-decoration: none;
          color: inherit;
        }
        .c-btn{
          display: unset;
          position: relative;
          top: 10px;
          @include pc{
            position: relative;
            top: -15px;
          }
        }
        
        &:hover{
          box-shadow: 3px 6px 10px gray;
          border-radius: 20px;
          transition: 0.5s;
        }
      }
    }
  }

  &--feature{
    margin-top: 130px;
    .l-container{
      @include pc{
        max-width: calc( 1400px + 5% * 2);
      }
    }
    &--item{
      margin-bottom: 40px;
      @include pc{
        display: flex;
      }
      &--figure{
        @include pc{
          position: relative;
          border-radius: 20px;
          width: 100%;
          &:hover{
            box-shadow: 0px 10px 0 #000;
            transform: translate3d(0, -10px, 0);
            transition: 0.5s;
          }
        }
        img{
          margin-bottom: 20px;
          border-radius: 20px;
          @include pc{
            margin-bottom: 0;
            width: 100%;
            height: 590px;
          }
        }
        &--text{
          padding-top: 15px;
          @include pc{
            position: absolute;
            right: 0;
            bottom: -1px;
            background-color: #FFF;
            padding: 35px 0 35px 35px;
            border-radius: 20px 0 0 0;
            width: 40%;
          }
        }
      }
      &--title{
        font-family: "ヒラギノ角ゴシック";
        line-height: 1.21;
        position: relative;
        @include pc{
          writing-mode: vertical-rl;
          text-align: left;
          font-size: 32px;
          padding: 117px 0 0 70px;
          padding-left: 70px;
          line-height: 1.4;
          font-weight: 600;
        }
        span{
          position: absolute;
          z-index: -1;
          font-family: "Helvetica Neue";
          -webkit-text-stroke: 1px #E6E6E6;
          color: #FFF;
          writing-mode: horizontal-tb;
          top: -22px;
          left: 0;
          font-size: 55px;
          @include pc{
            top: 0;
            left: 36px;
            font-size: 141px;
          }
          
        }
      }
      
      
    }
    .article-center{
      .p-front-1--feature--item--title{
        @include pc{
          padding: 117px 70px 0 0;
        }
        span{
          @include pc{
            left: -33px;
          }
        }
      }
      .p-front-1--feature--item--figure{
        &--text{
          @include pc{
            right: unset;
            left: -1px;
            padding: 35px 35px 35px 0;
            border-radius: 0 20px 0 0;
          }
        }
      }
    }
    .c-button-3{
      @include pc{
        max-width: 220px;
      }
    }
    .pc-block{
      display: none;
      @include pc{
        display: block;
      }
    }
    .sp-block{
      @include pc{
        display: none;
      }
    }
    .c-button-3{
      @include pc{
        margin: 0 auto;
        a{
          max-width: 250px;
        }
      } 
    }
  }
  

  


////////////////////////////////////

  








&--content--list{
  padding-bottom: 140px;
  margin-top: 100px;
  .p-front-1--list--title{
    font-size: 32px;
    text-align: center;
    padding: 40px 0;
  }
  .p-front-1--list{
    display: flex;
    flex-wrap: wrap;
    font-family: "ヒラギノ角ゴシック";
  }
  .p-front-1--item{
    position: relative;
    text-align: left;
    @include pc{
      padding-bottom: 40px;
    }
    &--figure{
      @include pc{
        width: 100%;
        height: 218px;
      }
      img{
        height: 100%;
        border-radius: 10px;
      }
    }
    &:not(:nth-of-type(3n)) {
      margin-bottom: 50px;
      @include pc{
        margin-right: 45px;
        margin-bottom: 0;
      }
    }
    @include pc{
      width: calc((100% - 45px * 2) / 3);
      padding-bottom: 80px;
    }
    
    &--title{
      font-size: 15px;
      padding: 20px 15px;
      line-height: 1.53;
      color: #231815;
    }
    &--tag{
      font-size: 14px;
      width: 28px;
      height: auto;
      background-color: #FFF;
      padding: 8px 5px;
      border-radius: 0 0 5px 5px;
      position: absolute;
      top: 0px;
      left: 15px;
      line-height: 1.42;
      writing-mode: vertical-lr;
      ul{
        padding-top: 0;
        margin: 0;
        list-style: none;
        li{
          color: #231815;
        }
      }
    }
    &--text{
      font-size: 12px;
      color: #AAAAAA;
      line-height: 1.75;
      padding:0 15px;
      margin-bottom: 20px;
      
    }
    .p-front-1--item--text--tag{
      padding: 0 10px;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      li{
        font-size: 12px;
        color: #AAAAAA;
        line-height: 1.75;
        padding: 0 5px;
        margin-bottom: 8px;
      }
    }
    a{
      text-decoration: none;
      display: block;
    }
    .c-btn{
      margin-left: 15px;
      display: unset;
      @include pc{
        position: absolute;
        bottom: 30px;
      }
    }
    &:hover{
      box-shadow: 3px 6px 10px gray;
      border-radius: 20px;
      transition: 0.5s;
    }
  }
}

  &--sns{
    position: relative;
    padding-bottom: 130px;
    &--title{
      background-color: #FFF;
      border-radius: 10px;
      text-orientation: upright;
      font-family: "ヒラギノ角ゴシック";
      font-weight: 600;
      padding-left: 20px;
      @include pc{
        font-size: 32px;
        writing-mode: vertical-lr;
        position: absolute;
        top: -45px;
        left: 200px;
        letter-spacing: 10px;
        padding-left: 0;
        z-index: 1;
      }
    }
    .title-2{
      @include pc{
        left: 135px;
      }
    }
    &--wrap{
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    ul{
      padding-left: 0;
      display: flex;
      animation: loop-slide 20s infinite linear 1s both;
      @include pc{
        
      }
      li{
        margin: 0 10px;
        list-style: none;
        width: 300px;
      }
    }
    @keyframes loop-slide {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    } 
    &--contents{
      @include pc{
        display: flex;
      justify-content: space-between;
      margin-top: 50px;
      }
      &--text{
        font-size: 15px;
        line-height: 2;
        @include pc{
          width: 50%;
        }
      }
      &--btn{
        @include pc{
          display: flex;
        }
        .c-button-3{
          width: 100%;
          @include pc{
            display: flex;
            margin-top: 0;
          }
          a{
            color: #231815;
            border: 1px solid #231815;
            background-color: #FFF;
            font-size: 14px;
            font-weight: normal;
            @include pc{
              width: 250px;
              height: 50px;
              margin-left: 15px;
            }
            &:after{
              background-image: url(../img/other/btn-arrow-bl.png);
            }
            &::before{
              background: #231815;
            }
            &:hover{
              color: #fff;
              .not-hover{
                display: none;
              }
              .btn-hover{
                display: inline-block;
              }
            }
            &:hover::after{
              background-image: url(../img/other/btn-arrow.png);
            }
            img{
              position: relative;
              top: -5px;
              left: -10px;
            }
            .btn-hover{
              display: none;
            }
          }
          &:nth-of-type(2){
             margin-top: 20px;
            @include pc{
              margin-top: 0px;
            }
          }
        }
      }
    }
  }

  &--price{
    padding-bottom: 90px;
    .l-container{
      @include pc{
        display: flex;
      }
    }
    &--contents{
      text-align: center;
      padding-bottom: 50px;
      @include pc{
       padding: 206px 40px 0px 40px;
       width: 60%;
      }
      &--text{
        line-height: 2;
      }
    }
    &--figure{
      width: 100%;
      @include pc{
       }

      figure{
        position: relative;
        margin-bottom: 10px;
        display: inline-block;
        .p-front-1--price--figure--text{
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: #FFF;
          font-family: "ヒラギノ角ゴシック";
          font-size: 18px;
          font-weight: 600;
          border-radius: 10px 0 0 10px;
          padding:15px;
          display: flex;
          flex-direction: column;
          line-height: 1.2;
          @include pc{
            bottom: unset;
            top: 100px;
          }
          b{
            font-size: 15px;
            font-weight: 300;
          }
        }
        
        &:nth-of-type(2){
          @include pc{
            margin-left: 62px;
          }
        }
        &:nth-of-type(3){
          @include pc{
            margin-left: 124px;
          }
        }
        a{
          text-decoration: none;
          color: inherit;
          display: block;
          height: 200px;
          img{
            height: 100%;
              width: 620px;
              object-fit: cover;
              border-radius: 20px;
          }
        }
        &:hover{
          opacity: 0.7;
        }
      }

    }
  }

  &--company{
    .l-container{
      position: relative;
    }
    &--figure{
      position: relative;
      z-index: -3;
      img{
        border-radius: 20px;
      }
    }
    &--contents{
      padding-top: 50px;
      @include pc{
        z-index: 0;
        position: absolute;
        bottom: 67px;
        right: 5%;
        border-radius: 20px 0 0 20px;
        background-color: #FFF;
        width: 40%;
        padding-left: 60px;
      }
      .p-front-1--title{
        @include pc{
          text-align: left;
        }
        &-en{
          font-size: 50px;
          top: -90px;
          @include pc{
            font-size: 80px;
            top: -120px;
          }
        }
      }
      &--text{
        line-height: 2;
        text-align: left;
        position: relative;
          top: -30px;
        @include pc{
          
        }
      }
      .c-button-3{
        position: relative;
        top: -30px;
        @include pc{
          justify-content: unset;
        }
      }
    }
    .pc-block{
      display: none;
      @include pc{
        display: block;
      }
    }
    .sp-block{
      @include pc{
        display: none;
      }
    }
  }


  .p-archive-4--content{
    padding-top: 100px;
    .l-container{
      @include pc{
        max-width: calc( 900px + 5% * 2);
      }
    }
  }
}






//見出しアニメーション
  
  @-webkit-keyframes passing-bar{
    0% {
      left: 0;
      right: auto;
      width: 0;
    }
    50% {
      left: 0;
      right: auto;
      width: 100%;
    }
    51% {
      left: auto;
      right: 0;
      width: 100%;
    }
    100% {
      left: auto;
      right: 0;
      width: 0;
    }
  }
  @keyframes passing-bar{
    0% {
      left: 0;
      width: 0;
    }
    50% {
      left: 0;
      width: 100%;
    }
    51% {
      left: 0;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 0;
    }
  }
  @-webkit-keyframes passing-txt{
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes passing-txt{
    0% { opacity:0; }
    50% { opacity:0; }
    100% { opacity:1; }
  }

  /* css */
 .move{
   opacity: 0;
 }
  .passing-bar {
    opacity: 1;
    position: relative;
    display: inline-block;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: 2s;
  }
  .passing-bar:before {
    content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
    /* 任意の値 */
    background: #000;
  }
.passing-txt {
    opacity: 0;
    /* 後ほど解説 */
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    /* 任意の値 */
    line-height: 1.5;
  }
  .passing-bar:before {
    -webkit-animation: passing-bar 1s ease 0s 1 normal forwards;
    animation: passing-bar 1s ease 0s 1 normal forwards;
  }
  .passing-txt {
    -webkit-animation: passing-txt 0s ease .5s 1 normal forwards;
    animation:passing-txt 0s ease 1s 1 normal forwards;
  }

  
  

//縦向き（特長部分）//pc
@-webkit-keyframes passing-bar-lr{
  0% {
    top: 0;
  }
  50% {
    top: 0;
    height: 100%;
  }
  51% {
    top: 0;
    height: 100%;
  }
  100% {
    top: 100%;
    height: 0;
  }
}
@keyframes passing-bar-lr{
  0% {
    top: 0;
  }
  50% {
    top: 0;
    height: 100%;
  }
  51% {
    top: 0;
    height: 100%;
  }
  100% {
    top: 100%;
    height: 0;
  }
}
@-webkit-keyframes passing-txt-lr{
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes passing-txt-lr{
  0% { opacity:0; }
  50% { opacity:0; }
  100% { opacity:1; }
}

/* css */
.move-lr{
 opacity: 0;
}
.passing-bar-lr {
  opacity: 1;
  position: relative;
  display: inline-block;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: 2s;
}
.passing-bar-lr:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #000;
}
.passing-txt-lr {
  opacity: 0;
  /* 後ほど解説 */
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* 任意の値 */
  line-height: 1.5;
}
.passing-bar-lr:before {
  -webkit-animation: passing-bar-lr 1s ease 0s 1 normal forwards;
  animation: passing-bar-lr 1s ease 0s 1 normal forwards;
}
.passing-txt-lr {
  -webkit-animation: passing-txt-lr 0s ease .5s 1 normal forwards;
  animation:passing-txt-lr 0s ease 2s 1 normal forwards;
}


//画像ふわっと表示
.fadein {
  opacity: 0;
  transform: translate(0, 100px);
  transition: all 1s;
}
.active {
  opacity: 1;
  transform: translate(0, 0);
}

  

  

 
